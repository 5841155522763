<template>
  <section>
    <div class="content-header">
      <h2>The importance of belonging </h2>
    </div>
    <div class="content-wrapper">
      <p>This toolkit presents mounting evidence that student success is linked to a sense of belonging.  </p>
      <p>That means that each and every one of us as faculty, instructors and educators has an obligation to create a learning space that fosters a sense of belonging in students.</p>
      <p>So why is a sense of belonging important?</p>
      <ul>
        <li class="pb-2">“Belonging is connected to persistence in higher education” (from the journal article, Sense of Belonging and Persistence in White and African-American First-Year Students)</li>
        <li>“Belonging is associated with academic outcomes such as participation and GPA, particularly for marginalized students” (from the journal article, Why Do Women Opt Out? Sense of Belonging and Women’s Representation in Mathematics)</li>
      </ul>
      <p>As faculty, instructors and educators, we can foster belonging by:</p>
      <ul>
        <li class="pb-2">Recognizing shared values among students (from the journal article, Constructing Belonging in a Diverse Campus Community)</li>
        <li>Establishing positive relationships with teachers and fellow students (from the journal article, Learning Environment, Interaction, Sense of Belonging and Study Success in Ethnically Diverse Student Groups)</li>
      </ul>
      <p class="lm">Learn more</p>
      <p>For more information about how you can foster a sense of belonging in your classroom, check out <a href="https://www.celt.iastate.edu/wp-content/uploads/2021/07/sense-of-belonging-practices.pdf" target="_blank">Strategies for Creating a Sense of Belonging</a>. This resource, by the Center for Excellence in Learning and Teaching (CELT) at Iowa State University, is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.ml" target="_blank">Creative Commons BY-NC-SA 4.0</a>.</p>
      <p>You can also check out <a href="https://covid19.ssri.psu.edu/articles/fostering-college-students-sense-belonging-amidst-covid-19" target="_blank">Fostering college students’ sense of belonging amidst COVID-19</a> from Penn State.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
